<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="grid-content bg-purple">
            <div style="display:inline-block" class="title_h mr-3">
              <el-select
                size="small"
                filterable
                clearable
                v-model="form.category_id"
                :placeholder="$t('message.choose_category')"
              >
                <el-option
                  v-for="(item, index) in categories"
                  :key="'category'+index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div style="display:inline-block" class="title_h mr-3">
              <el-input v-model="form.name" size="small" :placeholder="$t('message.name_template')"></el-input>
            </div>
            <!-- <div style="display:inline-block" class="title_h mr-3">
              <el-switch v-model="form.hide_header" :active-text="$t('message.title')"></el-switch>
            </div>
            <div style="display:inline-block" class="title_h mr-3">
              <el-switch v-model="form.hide_footer" :active-text="$t('message.running_title')"></el-switch>
            </div>
            <div style="display:inline-block" class="title_h">
              <el-select @change="change" clearable v-model="template" :placeholder="$t('message.templates')">
                <el-option
                  v-for="(item, index) in list"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div> -->
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button size="medium" type="success" @click="print">Печать</el-button> -->
              <el-button
                size="medium"
                :loading="save_loading"
                :disabled="save_loading ? true : false"
                type="success"
                @click="saveData"
              >{{ $t('message.save_and_close') }}</el-button>
              <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="block_Editor_Naw">
      <el-row>
        <el-col :span="24">
          <div class="documentation_content">
            <el-collapse v-model="activeName" accordion>
              <!-- <el-collapse-item :title="$t('message.page_header')" name="1">
                <div class="Editor_widht">
                  <Tinymce
                    ref="editor"
                    class="naw_Editor"
                    v-model="form.header_template"
                    :height="600"
                  />
                </div>
              </el-collapse-item> -->
              <el-collapse-item :title="$t('message.content')" name="2">
                <div class="Editor_widht">
                  <Tinymce ref="editor" class="naw_Editor" v-model="form.content" :height="600" />
                </div>
              </el-collapse-item>
              <!-- <el-collapse-item :title="$t('message.footer_header')" name="3">
                <div class="Editor_widht">
                  <Tinymce
                    ref="editor"
                    class="naw_Editor"
                    v-model="form.footer_template"
                    :height="600"
                  />
                </div>
              </el-collapse-item> -->
            </el-collapse>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import Tinymce from "@/components/Tinymce";
export default {
  mixins: [form],
  name: "createForm",
  components: {
    Tinymce
  },
  data() {
    return {
      activeName: "2",
      serchoInput: "",
      save_loading: false,
      update_loading: false,
      remove_loading: false,
      template: "",
      form: {
        name: "",
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false
      }
    };
  },
  computed: {
    ...mapGetters({
      list: "formTemplate/list",
      categories: "categoryDocumentation/list",
      model: "formTemplate/model"
    })
  },
  watch: {},
  created() {
    if (this.categories.length == 0) {
      this.categoryList();
    }
  },
  methods: {
    ...mapActions({
      updateList: "formTemplate/index",
      save: "formTemplate/store",
      update: "formTemplate/update",
      remove: "formTemplate/destroy",
      categoryList: "categoryDocumentation/index"
    }),
    // fetchList(){
    //     this.updateList({}).then(res => {
    //         this.list = res.data.data;
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // },
    saveData() {
      if (
        this.form.content != ""
      ) {
        this.save_loading = true;
        this.save(this.form)
          .then(res => {
            this.save_loading = false;
            this.resetForm("form");
            this.parent().listChanged();
            this.$alert(res);
            this.loadModel();
          })
          .catch(err => {
            this.save_loading = false;
            this.$alert(err);
          });
      } else {
        this.$alert({ code: 402, message: "Empty data", success: false });
      }
    },
    print() {
      let header =
        this.form.hide_header === true
          ? this.form.header_template + " <br>"
          : " ";
      let footer =
        this.form.hide_footer === true
          ? "<br> " + this.form.footer_template
          : " ";
      let DATA = header + this.form.content + footer;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(DATA);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    change(id) {
      let item = this.list.filter(item => {
        return item.id == id;
      });
      if (item.length) {
        this.form.id = item[0].id;
        this.form.name = item[0].name;
        this.form.hide_header = item[0].hide_header;
        this.form.hide_footer = item[0].hide_footer;
        this.form.header_template = item[0].header_template;
        this.form.content = item[0].content;
        this.form.footer_template = item[0].footer_template;
      } else {
        this.form = {
          name: "",
          header_template: "",
          content: "",
          header_template: "",
          hide_header: false,
          hide_footer: false
        };
      }
    },
    loadModel() {
      this.form = {
        name: "",
        header_template: "",
        content: "",
        header_template: "",
        hide_header: false,
        hide_footer: false
      };
      this.template = "";
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerCreate" });
    }
  }
};
</script>
<style lang="scss">
.naw_Editor {
  .mce-panel {
    border: 0 solid #c5c5c55e;
  }
}
.block_Editor_Naw {
  padding: 20px;
}
</style>